<template>
    <join-to-module-component/>
</template>

<script>
    import JoinToModuleComponent from "@/components/admin/JoinToModuleComponent";
    
    export default {
        name: "JoinToBHCModule",
        title: "Gestión Unete a Turismo BC",
        components: {JoinToModuleComponent}
    }
</script>

<style scoped>

</style>